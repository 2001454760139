/**
 * Faq_v2
 *
 * @selector [data-js="Faq_v2"]
 * @enabled true
 */
import {base} from 'app/util/base';

export default function Faq_v2() {
	// Private vars
	const instance = {};
	let flipButtons;
	let body;

	const bindEvents = () => {
		
		var acc = document.getElementsByClassName("Faq_v2--accordion");
		var i;
		
		for (i = 0; i < acc.length; i++) {
		  acc[i].addEventListener("click", function() {
			this.classList.toggle("Faq_v2--active");
			var panel = this.nextElementSibling;
			if (panel.style.maxHeight) {
			  panel.style.maxHeight = null;
			} else {
			  panel.style.maxHeight = panel.scrollHeight + "px";
			} 
		  });
		}


	};


	  

	const unbindEvents = () => {
	};

	// Public vars

	// Public methods
	instance.init = element => {
		instance.el = element;
		Object.assign(instance, base(instance));		
		flipButtons = element.querySelector('button.Faq_v2--accordion');		
		body = document.querySelector('body');

		bindEvents();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}